/* Custom Modal Styling */
.custom-modal .modal-content {
    border-radius: 10px;  
    border: 2px solid  #6c7fd8; 
    padding: 0px !important;
  }
  
  .custom-modal .modal-header {
    background-color:  #6c7fd8;
    color: white;
    font-size: 10px;
  }
  
  .custom-modal .modal-body {
    background-color: #f4f7fa;
   margin-left: 10px;
    font-size: 15px;
    color: #333;
    border-radius: 10px; 
  }
  
  .custom-modal .modal-footer {
    background-color: #f8f9fa; 
    padding: 1rem 1.5rem; 
  }
  
  .custom-modal .close-btn {
    background-color:  #6c7fd8;
    border: none;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
  }
  
  .custom-modal .close-btn:hover {
    background-color: #6c7fd8; 
    cursor: pointer;
  }
  
